import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PageLayout from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import UserAddForm from './UserAddForm';
import UserAddInfo from './UserAddInfo';

import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

const Page = styled(Box)(({ theme }) => ({
  
}));

const UserAdd = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUserCreated = (user) => {
    setUser(user);
  };

  return (
    <PageLayout>
      <Page>
        <PageTitle title={user ? "User & API Key" : "Add User"} loading={loading} />
        {user
          ? <UserAddInfo user={user}/>
          : <UserAddForm onUserCreated={handleUserCreated}/>
        }
      </Page>
    </PageLayout>
  );
};

export default UserAdd;

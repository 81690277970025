import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const PageTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
}));
const PageTitleHeader = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  marginTop: '0px',
  marginRight: '10px',
  marginBottom: '20px',
}));

const PageTitle = ({ title, loading, children }) => {
  return (
    <PageTitleWrapper>
      <PageTitleHeader variant="h2">
        {title}
      </PageTitleHeader>
      {loading &&
        <Box sx={{margin: '0px 10px'}}>
          <CircularProgress size={38} />
        </Box>
      }
      {children &&
        <Box sx={{marginLeft: '10px'}}>
          {children}
        </Box>
      }
    </PageTitleWrapper>
  );
};

export default PageTitle;
import React from 'react';
import { subHours, format } from 'date-fns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Define color palette
const colorPalette = [
  '#8884d8', // Purple
  '#82ca9d', // Green
  '#ffc658', // Yellow
  '#ff8042', // Orange
  '#00C49F', // Cyan
  '#0088FE', // Blue
];

const StackedBarChart = ({ data, backHours }) => {
  // 1. transform data
  const transformedData = data.reduce((acc, row) => {
    const time = row.time_cluster;

    let timeEntry = acc.find(entry => entry.timeCluster === time);
    if (!timeEntry) {
      timeEntry = { timeCluster: time, operations: {} };
      acc.push(timeEntry);
    }
    timeEntry.operations[row.operation_tag] = row.usage_total;

    return acc;
  }, []);

  // 2. detect operations
  const availableOperations = [];
  for (const record of transformedData) {
    for (const operation in record.operations) {
      if (!availableOperations.includes(operation)) {
        availableOperations.push(operation);
      }
    }
  }

  // 3. create a list of all possible hours in the last N hours
  const allTimeClusters = [];
  for (let i=0; i<backHours; i++) {
    const timeCluster = format(subHours(new Date(), i), 'yyyy-MM-dd HH:00:00');
    allTimeClusters.push(timeCluster);
  }
  allTimeClusters.reverse(); // Ensure the order is from oldest to newest

  // 4. create flat and consistent datamart, filling in missing hours
  const datamart = allTimeClusters.map(timeCluster => {
    const datarec = {
      timeCluster,
    };
    // 4.1. assign zero
    for (const operation of availableOperations) {
      datarec[operation] = 0;
    }
    // 4.2. find matching record and assign data
    const record = transformedData.find(rec => rec.timeCluster===timeCluster);
    if (record) {
      for (const operation in record.operations) {
        datarec[operation] = record.operations[operation];
      }
    }
    return datarec;
  });

  // Custom tick formatter to format XAxis labels
  const formatXAxis = (tickItem) => {
    return format(new Date(tickItem), 'HH:mm'); // Format to hours and minutes
  };

  return (
    <ResponsiveContainer height={600}>
      <BarChart
        width={500}
        height={300}
        data={datamart}
        margin={{
          top: 20, right: 0, left: 0, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timeCluster" tickFormatter={formatXAxis}  />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        {availableOperations.map((operation, index) => {
          return (
            <Bar key={operation} dataKey={operation} stackId="stack" fill={colorPalette[index % colorPalette.length]} />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;

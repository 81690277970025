import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Snackbar
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccessManager from '../../components/AccessManager';
import appConfig from '../../config.json';

const UserListTable = ({ search, onLoading }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fetchData = async (page, pageSize) => {
    onLoading && onLoading(true);
    try {
      // 1. get data from backend
      const baseUrl = appConfig.MasterData.baseUrl;
      const paginationLimit = pageSize;
      const paginationOffset = page * pageSize;
      const requestUrl = baseUrl
                       + '/api/admin/users'
                       + '?limit='  + paginationLimit
                       + '&offset=' + paginationOffset
                       + (search ? '&search=' + encodeURIComponent(search) : '')
      ;
      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + AccessManager.getAuthentication().token,
        },
      });
      const data = await response.json();

      // 2. set data into data table
      setUsers(data.users);
      setUsersCount(data.usersCount);
    } catch (err) {
      console.error(err);
    } finally {
      onLoading && onLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when page size changes
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchData(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    setPage(0);
    fetchData(page, pageSize);
  }, [search]);

  return (
    <Paper>
      <TablePagination
        component="div"
        count={usersCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>API Key</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>
                  <Tooltip title="Copy Email">
                    <IconButton onClick={() => handleCopy(user.email)}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {user.email}
                </TableCell>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>
                  {user.rest_key &&
                    <Tooltip title="Copy API Key">
                      <IconButton onClick={() => handleCopy(user.rest_key)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  }
                  {user.rest_key}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard"
      />
    </Paper>
  );
};

export default UserListTable;

import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UserIcon from '@mui/icons-material/People';
import UserAddIcon from '@mui/icons-material/PersonAdd';

const SidebarListItem = ({ icon, text, url }) => {
  const navigate = useNavigate();
  return (
    <ListItem>
      <ListItemButton onClick={() => navigate(url)}>
        <ListItemIcon sx={{ color: (theme) => theme.palette.primary.dark, paddingTop: 0, paddingBottom: 0 }}>
          {icon}
        </ListItemIcon>
        <ListItemText>
          <Typography variant="sidebarItem">
            {text}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const PageSidebar = ({ isSidebarOpen }) => {
  return (
    <Drawer
      variant={'temporary'}
      open={isSidebarOpen}
      sx={{
        width: (theme) => theme.layout.sidebar.width,
        [`& .MuiDrawer-paper`]: {
          width: (theme) => theme.layout.sidebar.width,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{ overflow: 'auto', marginTop: (theme) => theme.layout.topbar.height }}>
        <List>
          <SidebarListItem
            icon={<UserIcon />}
            text="Users"
            url="/users"
          />
          <SidebarListItem
            icon={<UserAddIcon />}
            text="Add User"
            url="/users/add"
          />
          <Divider sx={{ paddingTop: '5px', paddingBottom: '5px' }} />
        </List>
      </Box>
    </Drawer>
  );
};

export default PageSidebar;
import { useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import PageTopbar from './PageTopbar';
import PageSidebar from './PageSidebar';
import useMediaQuery from '@mui/material/useMediaQuery';

const PageContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.layout.topbar.height,
  padding: '25px',
}));

const PageLayout = ({ children }) => {
  const theme = useTheme();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <PageContainer theme={theme}>
      <PageTopbar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} />
      <PageSidebar isSidebarOpen={isSidebarOpen} />
      {children}
    </PageContainer>
  );
};

export default PageLayout;
import { BrowserRouter as Router, Routes, Route, redirect } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import TitleUpdater from './components/TitleUpdater';
import AccessManager from './components/AccessManager';
import AccessRequired from './components/AccessRequired';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import UserList from './pages/Users/UserList';
import UserAdd from './pages/Users/UserAdd';

const defaultTitle = "Atmosoar";
const titleSuffix  = " - Atmosoar";
const titlesMapping = {
  "/":                              defaultTitle,
  "/apis/metar-taf-data":           "Metar & TAF Data API" + titleSuffix,
  "/apis/weather-forecast-data":    "Weather Forecast Data" + titleSuffix,
  "apis/mission-safety-analysis":   "Mission Weather Impact Safety Analysis" + titleSuffix,
  "apis/melevation":                "Elevation API" + titleSuffix,
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TitleUpdater titlesMapping={titlesMapping} defaultTitle={defaultTitle} />
        <Routes>
          <Route path="/"           element={AccessManager.hasValidToken() ? <Home />     : <AccessRequired />} />
          <Route path="/users"      element={AccessManager.hasValidToken() ? <UserList /> : <AccessRequired />} />
          <Route path="/users/add"  element={AccessManager.hasValidToken() ? <UserAdd />  : <AccessRequired />} />
          <Route path="/login"      element={<Login />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
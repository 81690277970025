import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import PageTitle from "../../components/PageTitle";
import StackedBarChart from './ApiUsageChart';

import AccessManager from '../../components/AccessManager';
import appConfig from '../../config.json';

// styled component
const Page = styled(Box)(({ theme }) => ({
  
}));

// main component
const Home = () => {
  const [loading, setLoading] = useState(true);
  const [usageData, setUsageData] = useState(null);

  const backHours = 72
  const fetchUsageData = async () => {
    setLoading(true);
    try {
      // 1. get data from backend
      const baseUrl = appConfig.MasterData.baseUrl;
      const requestUrl = baseUrl
                       + '/api/admin/usage'
                       + '?backHours=' + backHours
      ;
      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + AccessManager.getAuthentication().token,
        },
      });
      const data = await response.json();

      // 2. set data into data table
      setUsageData(data.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsageData();
  }, []);

  return (
    <PageLayout>
      <Page>
        <PageTitle title={"API Usage"} loading={loading} />
        <Typography variant='h4' sx={{ marginTop: 0 }}>
          During the last {backHours} hours
        </Typography>
        <StackedBarChart data={usageData ?? []} backHours={backHours} />
      </Page>
    </PageLayout>
  );
};

export default Home;

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AccessManager from './AccessManager';

const AccessRequired = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(!AccessManager.hasValidToken()){
      navigate('/login');
    }
  }, [location]);

  return null;
};

export default AccessRequired;
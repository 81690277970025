import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button, Typography } from '@mui/material';
import AccessManager from './AccessManager';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

function PageTopbar({ isSidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();

  const handleLogout = (event) => {
    AccessManager.resetAuthentication();
    navigate('/login');
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.topbar }}>
      <Container maxWidth={null}>
        <Toolbar disableGutters>
          <IconButton 
            onClick={(e) => setSidebarOpen(!isSidebarOpen)}
            color="inherit"
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button href="/">
                <img src="/favicon.png" alt="Logo" style={{height: '50px'}} />
                <Typography variant="h6" sx={{ marginLeft: 2, fontWeight: 400, color:'white' }}>
                  ATMOSOAR
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button 
              onClick={(e) => handleLogout(e)}
              variant="text"
              sx={{ color: (theme) => theme.palette.common.white }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default PageTopbar;
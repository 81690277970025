import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

const UserAddInfo = ({ user }) => {

  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              value={user.fullName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={user.email}
            />
          </Grid>
          {user.apiKey &&
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="API Key"
                variant="outlined"
                value={user.apiKey}
              />
            </Grid>
          }
        </Grid>
      </Box>
    </Paper> 
  );
};

export default UserAddInfo;

export default class AccessManager {

    static hasValidToken(){
      const auth = AccessManager.getAuthentication();
      if(!auth.token || !auth.tokenExpiration){
        return false;
      }
      const tokenExpirationDatetime = new Date(auth.tokenExpiration);
      if(tokenExpirationDatetime<Date.now()){
        AccessManager.resetAuthentication();
        return false;
      }
      return true;
    };

    static getAuthentication(){
      // get token
      const token = localStorage.getItem('authentication.token');
      const tokenExpiration = localStorage.getItem('authentication.tokenExpiration');

      // get user data
      const userEmail = localStorage.getItem('authentication.user.email');

      return {
        token: token,
        tokenExpiration: new Date(tokenExpiration),
        user: {
            email: userEmail
        }
      }
    }

    static setAuthentication(token, tokenExpiration, email=null){
      // token data
      localStorage.setItem('authentication.token', token);
      localStorage.setItem('authentication.tokenExpiration', tokenExpiration);

      // user data
      localStorage.setItem('authentication.user.email', email);
    }

    static resetAuthentication(){
      // token data
      localStorage.removeItem('authentication.token');
      localStorage.removeItem('authentication.tokenExpiration');

      // user data
      localStorage.removeItem('authentication.user.email');
    }
}
import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import AccessManager from '../../components/AccessManager';
import appConfig from '../../config.json';

const UserAddForm = ({ onUserCreated, onLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [generateApiKey, setGenerateApiKey] = useState(true);

  const createUser = async () => {
    onLoading && onLoading(true);
    try {
      // 1. get data from backend
      const baseUrl = appConfig.MasterData.baseUrl;
      const requestUrl = baseUrl + '/api/admin/users';
      const response = await fetch(requestUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + AccessManager.getAuthentication().token,
        },
        body: JSON.stringify({
          fullName: fullName,
          email: email,
          password: password,
          generateDefaultApiKey: generateApiKey,
        }),
      });
      const data = await response.json();

      // 2. set data into data table
      onUserCreated && onUserCreated({
        fullName: fullName,
        email: email,
        apiKey: data.defaultApiKey ?? null,
      });
    } catch (err) {
      console.error(err);
    } finally {
      onLoading && onLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createUser();
  };

  return (
    <Paper>
      <Box padding={2}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={generateApiKey}
                    onChange={(e) =>
                      setGenerateApiKey(e.target.checked)
                    }
                  />
                }
                label="Generate API Key"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{textAlign: "right"}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{fontWeight: "bold"}}
              >
                Create User
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper> 
  );
};

export default UserAddForm;

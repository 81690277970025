import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import PageLayout from '../../components/PageLayout';
import PageTitle from "../../components/PageTitle";

import {
  Box,
  TextField,
} from '@mui/material';
import UserListTable from './UserListTable';

const PageSyles = styled(Box)(({ theme }) => ({
  
}));

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  return (
    <PageLayout>
      <PageSyles>
        <PageTitle title="Users" loading={loading}>
          <TextField
            onChange={(e) => handleSearch(e)}
            value={search}
            label="Search"
            type="search"
            variant="outlined"
            size="small"
            sx={{width: { lg: '400px' }}}
          />
        </PageTitle>
        <UserListTable search={search.trim()} onLoading={(status) => setLoading(status)} />
      </PageSyles>
    </PageLayout>
  );
};

export default UserList;

import React from 'react';
import { useState } from "react";
import { TextField, Button, Grid, Paper, Typography, Box } from '@mui/material';
import AccessManager from '../../components/AccessManager';
import appConfig from '../../config.json';
import windPlot from '../../images/weather-wind-plot.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const performLogin = async (event) => {
    try {
      // 1. get JWT from backend
      const baseUrl = appConfig.MasterData.baseUrl;
      const requestUrl = baseUrl + '/api/auth/user/login';
      const response = await fetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      const data = await response.json();

      // 2. save JWT and expiration
      AccessManager.setAuthentication(data.token, data.tokenExpiration, email);

      // 3. redirect to home
      window.location.href = '/';
    }
    catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = (event) => {
    // prevent default http submit
    event.preventDefault();

    // perform login
    performLogin();
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url('+windPlot+')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;